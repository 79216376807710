import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"


import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style.css'

import { injectIntl, useIntl } from "gatsby-plugin-intl"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ShippingPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(
    graphql`
    query {
      allContentfulShippingPage {
        nodes {
          id
          seoDescription
          seoTitle
          node_locale
          titre
          childContentfulShippingPageShippingTextRichTextNode {
            json
          }
          image{
            fluid{
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }`)
  return (
    <Layout >
      <SEO
        title={intl.formatMessage({ id: "aboutPage_seo_title" })}
        description={intl.formatMessage({ id: "aboutPage_seo_description" })}
      />
      <article className="aboutPageContainer">
        {
          data.allContentfulShippingPage.nodes.filter(artl => artl.node_locale === intl.locale).map(artl => {

            return (
              <section className="prodRow prodLeft" key={artl.id}>
                <section >
                  <h1>{artl.titre}</h1>
                  {documentToReactComponents(artl.childContentfulShippingPageShippingTextRichTextNode.json)}
                </section>
                <figure onContextMenu={(e) => e.preventDefault()} role='presentation'>
                  <Img fluid={artl.image.fluid} alt={artl.titre} title={artl.titre} />
                </figure>
              </section>
            )
          })
        }

      </article>
    </Layout >
  )
}

export default injectIntl(ShippingPage)
